<template>
  <div class="home page">
    <img src="../../assets/images/home-bg-top.png" class="header-img" />

    <img src="../../assets/images/logo.png" class="logo" />

    <div class="title">
      <h2>解码心音</h2>
      <p>测测您内心的【 爱力值 】</p>
    </div>

    <img src="../../assets/images/home-sound.png" class="line" />

    <div class="content">
      <p>每个人都渴望美好的感情</p>
      <p>但内心深处的您</p>
      <p>掌握着怎么神秘的「爱」的超能力？</p>
    </div>

    <div class="action">
      <button class="btn" @click="go('/step1')">
        快来测测看 <img src="../../assets/images/point.png" />
      </button>
    </div>
  </div>
</template> 
<script>
export default {
  name: "Home",

  mounted() {
    this.$store.dispatch("record/clean");
    this.$store.dispatch("share/config");
  },

  methods: {
    go(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style scoped lang="less">
.home {
  text-align: center;
  background: no-repeat bottom center / contain
    url("../../assets/images/home-bg-bottom.png");

  .header-img {
    width: 100%;
    display: block;
  }

  .logo {
    margin: 0 auto;
    width: 80%;
    display: block;
  }

  .title {
    margin: 20px 0 0 0;

    h2 {
      margin: 10px;
      font-size: 24px;
    }

    p {
      margin: 10px;
      font-size: 20px;
    }
  }

  .line {
    margin: 20px auto;
    width: 150px;
  }

  .content {
    margin: 0 0 20px 0;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
}
</style>